* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.container {
  max-width: 107rem;
  margin: 0 auto;
  padding: 0 4rem;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8e8e8e #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #8e8e8e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #878787;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e;
}

.mobile-menu {
  font-size: 2.3rem;
  cursor: pointer;
  display: none;
}

.mobile-menu:hover {
  color: #147efb;
  transition: all 0.3s;
}

@media (max-width: 900px) {
  .mobile-menu {
    display: flex;
  }
}

.closed-menu {
  left: -100% !important;
}

.open-menu {
  left: 0 !important;
}

.mobile-nav {
  display: flex;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.mobile-nav span {
  font-size: 3.3rem;
  position: absolute;
  top: 2rem;
  right: 4rem;
  cursor: pointer;
}

.mobile-nav span:hover {
  color: #147efb;
  transition: all 0.3s;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: 2.3rem;
  list-style: none;
}

.mobile-nav ul a {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.mobile-nav ul a:hover {
  color: #147efb;
  transition: all 0.3s;
}

nav {
  font-size: 1.7rem;
  width: 100%;
  height: auto;
  padding: 25px 40px 25px 50px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  z-index: 200;
  align-items: center;
}

@media (max-width: 400px) {
  nav {
    padding: 25px 30px 25px 30px;
  }
}

nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
}

nav ul a {
  color: #2d2e32;
  text-decoration: none;
  transition: all 0.23s;
  font-weight: 600;
}

@media (max-width: 900px) {
  nav ul a {
    display: none;
  }
}

nav ul a:hover {
  color: #147efb;
}

.logo {
  cursor: pointer;
  color: #2d2e32;
}

.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
}

@media (max-width: 900px) {
  .hero {
    height: auto;
    padding: 11rem 0;
  }
}

.hero-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  position: relative;
}

@media (max-width: 900px) {
  .hero-main {
    flex-direction: column-reverse;
    text-align: center;
    height: auto;
    gap: 3rem;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65rem;
  gap: 10rem;
  position: relative;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    text-align: center;
    height: auto;
    gap: 3rem;
  }
}

@media (min-width: 1600px) {
  .content {
    margin-top: 6rem;
  }
}

@media (min-width: 1900px) {
  .content {
    margin-top: 10rem;
  }
}

.hero-text {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  position: relative;
}

.hero-text h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  color: #2d2e32;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

@media (max-width: 500px) {
  .hero-text h1 {
    font-size: 4rem;
  }
}

.hero-text p {
  font-size: 1.8rem;
  font-weight: 500;
  color: #555555;
  font-family: "Mulish", sans-serif;
  line-height: 1.6;
}

@media (max-width: 900px) {
  .hero-text p {
    margin-bottom: 5rem;
  }
}

.hero-text span {
  display: flex;
  gap: 1.3rem;
  margin: 2.5rem 0;
  cursor: pointer;
  align-content: center;
  margin: auto;
  margin-top: 25px;
}

.hero-text span a {
  font-size: 3rem;
  color: #2d2e32;
  align-items: center;
}

@media (max-width: 900px) {
  .hero-text span {
    justify-content: center;
    margin-top: -2rem;
    margin-bottom: 4rem;
    gap: 1.6rem;
  }
}

.hero-text span i {
  transition: all 0.2s;
}

.hero-text span i:hover {
  color: #147efb;
}

.hero-text img {
  position: absolute;
  width: 6rem;
  height: 6rem;
  /* top: 8.7rem; */
  top: 5.0rem;
  right: 3rem;
}

@media (max-width: 900px) {
  .hero-text img {
    right: 3rem;
  }
}

@media (max-width: 500px) {
  .hero-text img {
    top: 6.4rem;
    right: 4rem;
    width: 4.5rem;
    height: 4.5rem;
  }
}

@media (max-width: 400px) {
  .hero-text img {
    right: 3rem;
  }
}

@media (max-width: 380px) {
  .hero-text img {
    right: 3rem;
  }
}

@media (max-width: 375px) {
  .hero-text img {
    right: 1.7rem;
  }
}

.hero-img {
  background-image: url("../images/pfp_webp.jpg");
  width: 35rem;
  height: 35rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 3px solid #2d2e32;
  animation: morph 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  position: relative;
  transition: all 1s ease-in-out;
}

@media (max-width: 500px) {
  .hero-img {
    width: 28rem;
    height: 28rem;
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.skills {
  width: 60%;
  display: flex;
  font-size: 1.7rem;
  color: #767676;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 900px) {
  .skills {
    position: initial;
    flex-direction: column;
  }
}

.skills p {
  margin-right: 7rem;
  padding-right: 2rem;
  border-right: 2px solid rgba(45, 46, 50, 0.5);
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #2d2e32;
}

@media (max-width: 900px) {
  .skills p {
    margin-right: 0;
    margin-bottom: 3rem;
    border-bottom: 2px solid rgba(45, 46, 50, 0.5);
    border-right: none;
    padding-right: 0;
    padding-bottom: 1rem;
  }
}

.skills ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
}

@media (max-width: 900px) {
  .skills ul {
    justify-content: center;
  }
}

.skills ul li {
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.skills ul li img {
  width: 3.4rem;
  height: 3.4rem;
}

.react-icon {
  margin-top: 0.5rem;
}

.about {
  background-color: white;
  padding: 15rem 0;
}

@media (max-width: 1020px) {
  .about {
    padding: 8rem 0;
  }
}

.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1020px) {
  .about-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.text-side {
  padding-right: 1.5rem;
}

@media (max-width: 1020px) {
  .text-side {
    max-width: 54rem;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .text-side {
    padding-right: 0;
  }
}

.text-side span {
  -webkit-text-decoration: underline #147efb;
  text-decoration: underline #147efb;
}

.text-side h3 {
  font-size: 1.7rem;
  color: #147efb;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1rem;
}

.text-side h4 {
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #2d2e32;
}

.text-side p {
  font-size: 1.7rem;
  font-family: "Mulish", sans-serif;
  color: #767676;
  font-weight: 500;
  line-height: 1.5;
}

.img-side {
  position: relative;
}

.img-side span {
  width: 19rem;
  height: 19rem;
  position: absolute;
  background-color: white;
  bottom: -33px;
  right: 33px;
  border-radius: 50%;
}

@media (max-width: 1020px) {
  .img-side span {
    width: 17rem;
    height: 17rem;
    bottom: -3px;
    right: 190px;
  }
}

@media (max-width: 880px) {
  .img-side span {
    bottom: -3px;
    right: 100px;
  }
}

@media (max-width: 750px) {
  .img-side span {
    display: none;
  }
}

.img-side span img {
  width: 19rem;
  animation: rotate 9s linear infinite;
}

@media (max-width: 1020px) {
  .img-side span img {
    width: 17rem;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.img-side__main-img {
  width: 41rem;
  height: 35rem;
  border-radius: 1.7rem;
}

@media (max-width: 1020px) {
  .img-side__main-img {
    margin-bottom: 3.5rem;
  }
}

@media (max-width: 460px) {
  .img-side__main-img {
    width: 90%;
    height: auto;
  }
}

.work-emoji {
  position: absolute;
  z-index: 10;
  width: 6rem !important;
  bottom: 35px;
  right: 98px;
}

@media (max-width: 1020px) {
  .work-emoji {
    bottom: 25px;
    right: 245px;
  }
}

@media (max-width: 880px) {
  .work-emoji {
    bottom: 57px;
    right: 156px;
  }
}

@media (max-width: 750px) {
  .work-emoji {
    display: none;
  }
}

.project {
  background-color: #f9f9f9;
  padding: 15rem 0;
}

@media (max-width: 1020px) {
  .project {
    padding: 8rem 0;
  }
}

.project-content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .project-content {
    text-align: center;
  }
}

.project-content p {
  color: #147efb;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1rem;
}

.project-content h3 {
  font-size: 2.5rem;
  color: #2d2e32;
  margin-bottom: 6rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  width: 100%;
  height: auto;
  gap: 5rem;
}

.projects-grid .pro {
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1.7rem;
}

.projects-grid .pro__1 {
  height: 40rem;
  flex-direction: row;
  padding: 2rem;
  gap: 8rem;
}

@media (max-width: 1020px) {
  .projects-grid .pro__1 {
    height: 75rem;
    flex-direction: column !important;
    gap: 3rem;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .projects-grid .pro__1 {
    width: 100%;
  }
}

.projects-grid .pro__img {
  width: 53rem;
  height: auto;
  border-radius: 1.7rem;
  background-color: rgba(118, 118, 118, 0.2);
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 650px) {
  .projects-grid .pro__img {
    width: 100%;
  }
}

.projects-grid .pro__img img {
  width: 100%;
  height: auto;
}

.projects-grid .pro__text {
  display: flex;
  width: 30rem;
  height: auto;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

@media (max-width: 1020px) {
  .projects-grid .pro__text {
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .projects-grid .pro__text {
    width: 100%;
  }
}

.projects-grid .pro__text img {
  width: 7rem;
  position: absolute;
  top: -2px;
  right: -19px;
}

.projects-grid .pro__text h3 {
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #2d2e32;
  margin-bottom: 2rem;
  position: relative;
}

.projects-grid .pro__text p {
  font-size: 1.7rem;
  color: #767676;
  text-align: center;
  text-transform: none;
  font-weight: 500;
}

.stack {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.stack p {
  color: black !important;
  font-weight: 600 !important;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.3rem;
  font-family: "Mulish", sans-serif !important;
}

.links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
  gap: 1rem;
  align-items: center;
}

.links a {
  font-size: 1.7rem;
  color: #2d2e32;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.links a i {
  font-size: 2.8rem;
}

.links a .link-icon {
  font-size: 2rem !important;
}

.links a img {
  width: 3.5rem;
}

.links a:hover {
  color: #147efb;
  transition: all 0.3s;
}

.reversed-proj {
  flex-direction: row-reverse !important;
}

@media (max-width: 460px) {
  .container {
    padding: 0 1.7rem;
  }
}

.new-projects {
  font-size: 2.6rem;
  text-align: center;
  color: #2d2e32;
}

.contact {
  background-color: white;
  padding: 11rem 0;
}

@media (max-width: 1020px) {
  .contact {
    padding: 8rem 0;
  }
}

.contact__content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 750px) {
  .contact__title {
    text-align: center;
  }
}

.contact__title p {
  color: #147efb;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.contact__title h3 {
  color: #2d2e32;
  font-size: 2.5rem;
}

.contact__icons {
  display: flex;
  gap: 8rem;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 6rem;
}

@media (max-width: 750px) {
  .contact__icons {
    flex-direction: column;
    gap: 5rem;
    justify-content: center;
    text-align: center;
  }
}

.contact__icon-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 750px) {
  .contact__icon-box {
    flex-direction: column;
  }
}

.contact__icon-box span {
  background-color: white;
  padding: 2.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.contact__icon-box span i {
  font-size: 3rem;
  color: #147efb;
}

.contact__info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact__info h3 {
  font-size: 1.7rem;
  color: #2d2e32;
}

.contact__info a,
.contact__info p {
  font-size: 1.7rem;
  color: #767676;
  text-decoration: none;
  cursor: pointer;
}

.contact__info a:hover,
.contact__info p:hover {
  color: #147efb;
  transition: all 0.3s;
}

footer {
  background-color: #2d2e32;
  padding: 5rem 0;
  width: 100%;
}

footer h3 {
  font-size: 1.7rem;
  color: white;
}

.footerc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 550px) {
  .footerc {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    text-align: center;
  }
}

.footerc__socials {
  display: flex;
  gap: 2rem;
}

.footerc__socials i {
  font-size: 2.3rem;
  color: white;
}

.footerc__socials i:hover {
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}

/*# sourceMappingURL=styles.css.map */